import { useLoaderData } from '@remix-run/react';

import type { loader as indexLoader } from '../routes/_index.tsx';

/**
 * useSupportedLanguages returns languages supported by the application,
 * defined on the server as `languages` env var.
 */
function useSupportedLanguages() {
  const { supportedLanguages } = useLoaderData<typeof indexLoader>();

  return supportedLanguages || [];
}

export default useSupportedLanguages;

/**
 * @param alignment - center, left or right.
 * @returns appropriate align-items tailwind css class
 */
export function getAlignItemsClassName({
  alignment,
  display,
  itemSize,
}: {
  alignment: string;
  display: string;
  itemSize: string;
}) {
  if (itemSize === 'fill') return 'items-stretch';

  if (display === 'vertical') {
    switch (alignment) {
      case 'center':
        return 'place-items-center';
      case 'right':
        return 'place-items-end';
      case 'left':
      default:
        return 'place-items-start';
    }
  }

  switch (alignment) {
    case 'center':
      return 'items-center';
    case 'right':
      return 'items-end';
    case 'left':
    default:
      return 'items-start';
  }
}

/**
 * @param alignment - center, left or right.
 * @returns appropriate justify-content tailwind css class
 */
export function getJustifyContentClassName(alignment: string) {
  switch (alignment) {
    case 'center':
      return 'justify-center';
    case 'right':
      return 'justify-end';
    case 'left':
    default:
      return 'justify-start';
  }
}

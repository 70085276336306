const manifest = {
  plugin_id: 3580,
  identifier: 'responsive_nav_bar_button',
  manifest_version: '0.0.1-alpha-14',
  dependency_name: '',
  name: 'Navbar button',
  description: 'Navbar button',
  type: 'nav_item',
  assets: {
    fields: [
      {
        key: 'asset_switch',
        type: 'switch',
        label: 'Enable',
        initial_value: false,
      },
      {
        key: 'asset_width',
        label: 'Width',
        type: 'number_input',
        initial_value: 24,
        conditional_fields: [
          {
            key: 'assets/asset_switch',
            condition_value: true,
          },
        ],
      },
      {
        key: 'asset_height',
        label: 'Height',
        type: 'number_input',
        initial_value: 24,
        conditional_fields: [
          {
            key: 'assets/asset_switch',
            condition_value: true,
          },
        ],
      },
      {
        key: 'button_default_asset',
        label: 'Default asset',
        type: 'uploader',
        conditional_fields: [
          {
            key: 'assets/asset_switch',
            condition_value: true,
          },
        ],
      },
      {
        key: 'button_hover_asset_switch',
        label: 'Enable hover asset',
        type: 'switch',
        initial_value: false,
        conditional_fields: [
          {
            key: 'assets/asset_switch',
            condition_value: true,
          },
          {
            key: 'styles/mode',
            condition_value: 'button',
          },
        ],
      },
      {
        key: 'button_hover_asset',
        label: 'Hover asset',
        type: 'uploader',
        conditional_fields: [
          {
            key: 'assets/asset_switch',
            condition_value: true,
          },
          {
            key: 'styles/mode',
            condition_value: 'button',
          },
        ],
      },
      {
        key: 'button_selected_asset_switch',
        label: 'Enable selected asset',
        type: 'switch',
        initial_value: false,
        conditional_fields: [
          {
            key: 'assets/asset_switch',
            condition_value: true,
          },
          {
            key: 'styles/mode',
            condition_value: 'button',
          },
        ],
      },
      {
        key: 'button_selected_asset',
        label: 'Selected asset',
        type: 'uploader',
        conditional_fields: [
          {
            key: 'assets/asset_switch',
            condition_value: true,
          },
          {
            key: 'styles/mode',
            condition_value: 'button',
          },
        ],
      },
      {
        key: 'button_selected_hover_asset_switch',
        label: 'Enable selected & hover asset',
        type: 'switch',
        initial_value: false,
        conditional_fields: [
          {
            key: 'assets/asset_switch',
            condition_value: true,
          },
          {
            key: 'styles/mode',
            condition_value: 'button',
          },
        ],
      },
      {
        key: 'button_selected_hover_asset',
        label: 'Selected & hover asset',
        type: 'uploader',
        conditional_fields: [
          {
            key: 'assets/asset_switch',
            condition_value: true,
          },
          {
            key: 'styles/mode',
            condition_value: 'button',
          },
        ],
      },
      {
        key: 'asset_alignment',
        label: 'Alignment',
        type: 'select',
        options: [
          {
            text: 'Left',
            value: 'left',
          },
          {
            text: 'Right',
            value: 'right',
          },
        ],
        initial_value: 'left',
        conditional_fields: [
          {
            key: 'assets/asset_switch',
            condition_value: true,
          },
        ],
      },
      {
        key: 'asset_margin_top',
        label: 'Margin top',
        type: 'number_input',
        initial_value: 0,
        conditional_fields: [
          {
            key: 'assets/asset_switch',
            condition_value: true,
          },
        ],
      },
      {
        key: 'asset_margin_right',
        label: 'Margin right',
        type: 'number_input',
        initial_value: 8,
        conditional_fields: [
          {
            key: 'assets/asset_switch',
            condition_value: true,
          },
        ],
      },
      {
        key: 'asset_margin_bottom',
        label: 'Margin bottom',
        type: 'number_input',
        initial_value: 0,
        conditional_fields: [
          {
            key: 'assets/asset_switch',
            condition_value: true,
          },
        ],
      },
      {
        key: 'asset_margin_left',
        label: 'Margin left',
        type: 'number_input',
        initial_value: 0,
        conditional_fields: [
          {
            key: 'assets/asset_switch',
            condition_value: true,
          },
        ],
      },
    ],
  },
  styles: {
    fields: [
      {
        key: 'mode',
        label: 'Button Mode',
        type: 'select',
        options: [
          {
            text: 'Button',
            value: 'button',
          },
          {
            text: 'Inactive',
            value: 'inactive',
          },
        ],
        initial_value: 'button',
      },
      {
        key: 'size',
        label: 'Button Size',
        type: 'select',
        options: [
          {
            text: 'Dynamic',
            value: 'dynamic',
          },
          {
            text: 'Fixed',
            value: 'fixed',
          },
        ],
        initial_value: 'dynamic',
      },
      {
        key: 'width',
        label: 'Button width',
        type: 'number_input',
        initial_value: 120,
        conditional_fields: [
          {
            key: 'styles/size',
            condition_value: 'fixed',
          },
        ],
      },
      {
        key: 'button_content_alignment',
        label: 'Button Content Alignment',
        type: 'select',
        options: [
          {
            text: 'Left',
            value: 'left',
          },
          {
            text: 'Center',
            value: 'center',
          },
          {
            text: 'Right',
            value: 'right',
          },
        ],
        initial_value: 'center',
        conditional_fields: [
          {
            key: 'styles/size',
            condition_value: 'fixed',
          },
        ],
      },
      {
        group: true,
        label: 'Title',
        folded: false,
        fields: [
          {
            key: 'title_switch',
            label: 'Enable',
            type: 'switch',
            initial_value: true,
          },
          {
            key: 'button_title_font_color',
            label: 'Default font color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(158, 158, 158, 1)',
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'button_title_hover_font_color',
            label: 'Hover font color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(239, 239, 239, 1)',
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
              {
                key: 'styles/mode',
                condition_value: 'button',
              },
            ],
          },
          {
            key: 'button_title_selected_font_color',
            label: 'Selected font color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(239, 239, 239, 1)',
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
              {
                key: 'styles/mode',
                condition_value: 'button',
              },
            ],
          },
          {
            key: 'button_title_selected_hover_font_color',
            label: 'Selected & hover font color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(239, 239, 239, 1)',
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
              {
                key: 'styles/mode',
                condition_value: 'button',
              },
            ],
          },
          {
            key: 'title_font_family',
            label: 'Font family',
            type: 'web_font_selector',
            initial_value: 'Ubuntu-Medium',
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'title_font_size',
            label: 'Font size',
            type: 'number_input',
            initial_value: 14,
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'title_line_height',
            label: 'Line height',
            type: 'number_input',
            initial_value: 24,
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'title_letter_spacing',
            label: 'Letter spacing',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'title_text_transform',
            label: 'Text transform',
            type: 'select',
            options: [
              { text: 'None', value: 'none' },
              { text: 'Capitalize', value: 'capitalize' },
              { text: 'Uppercase', value: 'uppercase' },
              { text: 'Lowercase', value: 'lowercase' },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'button_title_default_text_decoration',
            label: 'Default text decoration',
            type: 'select',
            options: [
              { text: 'None', value: 'none' },
              { text: 'Underline', value: 'underline' },
              { text: 'Strikethrough', value: 'line-through' },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
              {
                key: 'styles/mode',
                condition_value: 'button',
              },
            ],
          },
          {
            key: 'button_title_hover_text_decoration',
            label: 'Hover text decoration',
            type: 'select',
            options: [
              { text: 'None', value: 'none' },
              { text: 'Underline', value: 'underline' },
              { text: 'Strikethrough', value: 'line-through' },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
              {
                key: 'styles/mode',
                condition_value: 'button',
              },
            ],
          },
          {
            key: 'button_title_selected_text_decoration',
            label: 'Selected text decoration',
            type: 'select',
            options: [
              { text: 'None', value: 'none' },
              { text: 'Underline', value: 'underline' },
              { text: 'Strikethrough', value: 'line-through' },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
              {
                key: 'styles/mode',
                condition_value: 'button',
              },
            ],
          },
          {
            key: 'button_title_selected_hover_text_decoration',
            label: 'Selected & hover text decoration',
            type: 'select',
            options: [
              { text: 'None', value: 'none' },
              { text: 'Underline', value: 'underline' },
              { text: 'Strikethrough', value: 'line-through' },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
              {
                key: 'styles/mode',
                condition_value: 'button',
              },
            ],
          },
        ],
      },
      {
        group: true,
        label: 'Button Styling',
        folded: false,
        fields: [
          {
            key: 'button_default_underline_color',
            label: 'Default underline color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
          },
          {
            key: 'button_hover_underline_color',
            label: 'Hover underline color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
            conditional_fields: [
              {
                key: 'styles/mode',
                condition_value: 'button',
              },
            ],
          },
          {
            key: 'button_selected_underline_color',
            label: 'Selected underline color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
            conditional_fields: [
              {
                key: 'styles/mode',
                condition_value: 'button',
              },
            ],
          },
          {
            key: 'button_selected_hover_underline_color',
            label: 'Selected & hover underline color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
            conditional_fields: [
              {
                key: 'styles/mode',
                condition_value: 'button',
              },
            ],
          },
          {
            key: 'underline_thickness',
            label: 'Underline thickness',
            type: 'number_input',
            initial_value: 0,
          },
          {
            key: 'underline_corner_radius',
            label: 'Underline corner radius',
            type: 'number_input',
            initial_value: 0,
          },
          {
            key: 'item_margin_top',
            label: 'Item margin top',
            type: 'number_input',
            initial_value: 0,
          },
          {
            key: 'item_margin_right',
            label: 'Item margin right',
            type: 'number_input',
            initial_value: 0,
          },
          {
            key: 'item_margin_bottom',
            label: 'Item margin bottom',
            type: 'number_input',
            initial_value: 0,
          },
          {
            key: 'item_margin_left',
            label: 'Item margin left',
            type: 'number_input',
            initial_value: 0,
          },
          {
            key: 'item_padding_top',
            label: 'Item padding top',
            type: 'number_input',
            initial_value: 8,
          },
          {
            key: 'item_padding_right',
            label: 'Item padding right',
            type: 'number_input',
            initial_value: 16,
          },
          {
            key: 'item_padding_bottom',
            label: 'Item padding bottom',
            type: 'number_input',
            initial_value: 8,
          },
          {
            key: 'item_padding_left',
            label: 'Item padding left',
            type: 'number_input',
            initial_value: 16,
          },
          {
            key: 'button_default_item_background_color',
            label: 'Default item background color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
          },
          {
            key: 'button_hover_item_background_color',
            label: 'Hover item background color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(30, 30, 30, 1)',
            conditional_fields: [
              {
                key: 'styles/mode',
                condition_value: 'button',
              },
            ],
          },
          {
            key: 'button_selected_item_background_color',
            label: 'Selected item background color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
            conditional_fields: [
              {
                key: 'styles/mode',
                condition_value: 'button',
              },
            ],
          },
          {
            key: 'button_selected_hover_item_background_color',
            label: 'Selected & hover item background color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(30, 30, 30, 1)',
            conditional_fields: [
              {
                key: 'styles/mode',
                condition_value: 'button',
              },
            ],
          },
          {
            key: 'button_default_item_border_color',
            label: 'Default item border color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
          },
          {
            key: 'button_hover_item_border_color',
            label: 'Hover item border color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
            conditional_fields: [
              {
                key: 'styles/mode',
                condition_value: 'button',
              },
            ],
          },
          {
            key: 'button_selected_item_border_color',
            label: 'Selected item border color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
            conditional_fields: [
              {
                key: 'styles/mode',
                condition_value: 'button',
              },
            ],
          },
          {
            key: 'button_selected_hover_item_border_color',
            label: 'Selected & hover item border color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
            conditional_fields: [
              {
                key: 'styles/mode',
                condition_value: 'button',
              },
            ],
          },
          {
            key: 'item_border_thickness',
            label: 'Item border thickness',
            type: 'number_input',
            initial_value: 0,
          },
          {
            key: 'item_corner_radius',
            label: 'Item corner radius',
            type: 'number_input',
            initial_value: 8,
          },
        ],
      },
    ],
  },
  data: {
    fields: [
      {
        key: 'target',
        label: 'Target screen',
        type: 'screen_selector',
        conditional_fields: [
          {
            key: 'styles/mode',
            condition_value: 'button',
          },
        ],
      },
    ],
  },
  localizations: {
    fields: [
      {
        type: 'text_input',
        label: 'Label',
        key: 'label',
      },
    ],
  },
} as const;

export default manifest;

import { z } from 'zod';
import type { UIComponent } from '~/services/layout';
import {
  getUiComponentResponsiveCellIds,
  type ResponsiveCellIds,
} from '~/services/layout/layout';

/**
 * Validates if the input is a valid Zod URL & starts with http which covers both http & https
 * @param url string or URL object to validate
 * @returns boolean
 */
export function isValidUrl(url: string | URL | undefined | null): boolean {
  if (!url) return false;

  if (url instanceof URL) url = url.href;

  if (typeof url !== 'string') return false;

  if (!url.startsWith('http')) return false;

  const urlZodSchema: z.ZodString = z.string().url();

  const checkUrl = urlZodSchema.safeParse(url);

  return checkUrl.success;
}

export function getUrlStringOrUndefined(
  url: string | undefined
): string | undefined {
  return isValidUrl(url) ? url : undefined;
}

export function getURLSegments(url: string) {
  return url?.split('/')?.filter((segment) => segment !== '');
}

export function validateSearchQuery(query: string): boolean {
  // Accepts one or more Latin alphabet characters (lowercase and uppercase),
  // numbers, special characters and a space character

  const re = new RegExp(
    /^[\p{L}\p{M}\p{N}!"#$%&'()*+,\-./:;<=>?@[\]^_`±{|}~\\ ]+$/u
  );

  return re.test(query);
}

/**
 * Invalid UI components are those that point to missing cell styles
 * and does not have an id or component_type
 * @param layout - layout object
 * @param cellStyles - cellStyles object
 * @returns - false if the UI component is invalid, otherwise true
 */
export function validateUiComponent({
  uiComponent,
  cellStyles,
}: {
  uiComponent: UIComponent;
  cellStyles: any;
}): boolean {
  if (!uiComponent?.id || !uiComponent?.component_type) return false;

  const {
    mobileCellId,
    tabletCellId,
    desktopCellId,
    largeDesktopCellId,
  }: ResponsiveCellIds = getUiComponentResponsiveCellIds(uiComponent);

  if (mobileCellId && !cellStyles[mobileCellId]) return false;
  if (tabletCellId && !cellStyles[tabletCellId]) return false;
  if (desktopCellId && !cellStyles[desktopCellId]) return false;
  if (largeDesktopCellId && !cellStyles[largeDesktopCellId]) return false;

  return true;
}
